@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

* {
	font-family: 'Montserrat', sans-serif;
	word-wrap: break-word;
	scroll-behavior: smooth;
	box-sizing: border-box;
}

body {
	margin: 0;
	-webkit-font-smoothing: subpixel-antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.display-none {
	display: none;
}
